.job_container {
    display: grid;
    grid-template-columns: repeat(3, .6fr);
    gap: 2rem;
}

.job {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.job:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.job_head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.job_head h3 {
    color: var(--color-bg);
    text-align: center;
    font-size: 1rem;
}

.job_head h5 {
    color: var(--color-white);
    text-align: center;
    font-size: .9rem;
}


.job_head h6 {
    color: var(--color-white);
    font-size: 0.8rem;
    text-align: center;
}

.job_list {
    padding: 2rem;
    size: 50%;
}

.job_list li {
    font-size: 12px;
    display: flex;
    gap: 1rem;
    margin-bottom: .5rem;
    list-style: disc;
}

.service_list-icon {
    color: var(--color-primary);
    margin-top: 2px;
    font-size: 1.5rem;
}

.service_list p {
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
    .services_container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service {
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .services_container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}