.portfolio_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    justify-content: center;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}


.portfolio_item-image {
    border: 1px solid transparent;
    border-radius: 1.5rem;
    overflow: hidden;
    
}

.portfolio_item h3 {
    margin: 1.2rem 0 0rem;
}

.portfolio_item h5 {
    margin: .8rem 0 1rem;
}



.portfolio_item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
.links {
    display: flex;
}
.git-icon {
    margin-top: 5px;
    margin-right: .5rem;
}

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .btn-primary.slides {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .btn-primary.slides {
        display: none;
    }
}